<template>
  <b-sidebar
    id="sidebar-task-handler"
    :visible="isTaskHandlerSidebarActiveEdit"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("message.todo.editTodo") }}</h5>
        <div>
          <feather-icon
            v-if="todoData.userEmail === userData.email"
            icon="TrashIcon"
            class="cursor-pointer"
            @click="
              deleteTodo();
              hide();
            "
          />
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
      </div>

      <b-overlay
        variant="transparent"
        opacity="1.00"
        blur="2px"
        :show="todoData.length === 0"
        rounded="sm"
      >
        <!-- BODY -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(editTodo())"
            @reset.prevent="resetForm"
          >
            <b-form-group
              label-for="todo-title"
              v-if="todoData.userEmail != userData.email"
            >
              <template v-slot:label> {{ $t("message.todo.user") }} </template>
              <b-form-input
                id="todo-title"
                v-model="todoData.userEmail"
                disabled
              />
            </b-form-group>

            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="Title"
              rules="required"
            >
              <b-form-group label-for="todo-title">
                <template v-slot:label>
                  {{ $t("message.todo.title") }}
                </template>
                <b-form-input
                  id="todo-title"
                  v-model="todoData.title"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Description -->
            <validation-provider name="descrição" rules="required">
              <b-form-group label-for="description">
                <template v-slot:label>
                  {{ $t("message.todo.description") }}
                </template>
                <quill-editor
                  id="title"
                  style="margin-bottom: 82px; height: 200px"
                  :options="editorOption"
                  v-model="todoData.description"
                />
              </b-form-group>
            </validation-provider>

            <!-- Start Date -->
            <validation-provider
              #default="validationContext"
              name="data inicial"
              rules="required"
            >
              <b-form-group label-for="startDate">
                <template v-slot:label>
                  {{ $t("message.todo.startDate") }}
                </template>
                <flat-pickr
                  v-model="todoData.startDate"
                  class="form-control"
                  :config="flatConfig"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  <template>
                    {{ $t("message.list.dateStart") }}
                  </template>
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- End Date -->
            <validation-provider
              #default="validationContext"
              name="data final prevista"
              rules="required"
            >
              <b-form-group label-for="endDate">
                <template v-slot:label>
                  {{ $t("message.todo.endDate") }}
                </template>
                <flat-pickr
                  v-model="todoData.endDate"
                  class="form-control"
                  :config="flatConfig"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  <template>
                    {{ $t("message.list.dateEnd") }}
                  </template>
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Type -->
            <validation-provider
              #default="validationContext"
              name="tipo"
              rules="required"
              v-if="todoData.userEmail === userData.email"
            >
              <b-form-group
                label-for="type"
                :state="getValidationState(validationContext)"
              >
                <template v-slot:label>
                  {{ $t("message.todo.type") }}
                </template>
                <v-select
                  v-model="todoData.type"
                  :options="todoTypeOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="todo-status"
                />
              </b-form-group>
            </validation-provider>

            <validation-provider name="tipo" rules="required" v-else>
              <b-form-group label-for="type">
                <template v-slot:label>
                  {{ $t("message.todo.type") }}
                </template>
                <v-select
                  v-model="todoData.type"
                  :options="todoTypeOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="todo-status"
                  disabled
                />
              </b-form-group>
            </validation-provider>

            <!-- Priority -->
            <validation-provider
              #default="validationContext"
              name="prioridade"
              rules="required"
            >
              <b-form-group
                label-for="priority"
                :state="getValidationState(validationContext)"
              >
                <template v-slot:label>
                  {{ $t("message.todo.priority") }}
                </template>
                <v-select
                  v-model="todoData.priority"
                  :options="todoPriorityOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="todo-status"
                />
              </b-form-group>
            </validation-provider>
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                v-if="!disabledButton"
              >
                {{ $t("message.todo.save") }}
              </b-button>

              <b-button
                v-else
                disabled
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
              >
                {{ $t("message.todo.save") }}
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                {{ $t("message.buttons.cancel") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import date from "@/@core/utils/date";
import dateInputEdit from "@/@core/utils/dateInputEdit";
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,
    BFormTextarea,
    BOverlay,
    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isTaskHandlerSidebarActiveEdit",
    event: "update:is-task-handler-sidebar-active",
  },
  props: {
    isTaskHandlerSidebarActiveEdit: {
      type: Boolean,
      required: true,
    },
    todoTypeOptions: {
      type: Array,
      required: true,
    },
    todoPriorityOptions: {
      type: Array,
      required: true,
    },
    todoDataEdit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      userData: store.state.user.userData,
      flatConfig: { altFormat: "d/m/Y", altInput: true, format: "d/m/Y" },
      disabledButton: false,

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInputEdit(newVal);
    },
    todoDataEdit: function (newVal, oldVal) {
      this.todoData = this.todoDataEdit;
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInputEdit(this.$i18n.locale);
    }
  },

  methods: {
    editTodo() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.disabledButton = true;
          this.onSubmit();

          axios({
            method: "put",
            url: `${URL_API}todo/${this.todoData.id}`,
            headers: getHeader(this.userData),
            data: this.todoData,
          })
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$i18n.t("message.toast.updated"),
                  icon: "CheckIcon",
                  variant: "success",
                  text: this.$i18n.t("message.todo.updated"),
                },
              });
            })
            .then(() => {
              this.$root.$emit("newListTodo", null);
              this.disabledButton = false;
            })
            .catch((error) => {
              this.disabledButton = false;
              console.log(error);
            });
        }
      });
    },

    deleteTodo() {
      axios({
        method: "delete",
        url: `${URL_API}todo/${this.todoData.id}`,
        headers: getHeader(this.userData),
        data: this.todoData,
      })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: this.$i18n.t("message.todo.deleted"),
            },
          });
        })
        .then(() => {
          this.$root.$emit("newListTodo", null);
          this.disabledButton = false;
        })
        .catch((error) => {
          this.disabledButton = false;
          console.log(error);
        });
    },
  },

  setup(props, { emit }) {
    const blanktodoData = {};

    const todoData = ref(JSON.parse(JSON.stringify(blanktodoData)));
    const resettodoData = () => {
      todoData.value = JSON.parse(JSON.stringify(blanktodoData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-task-handler-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resettodoData);

    return {
      todoData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-todo-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
