<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="todo-app-list">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">
        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->

        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
                @click="searchByValue(searchValue)"
              />
            </b-input-group-prepend>

            <b-form-input
              v-model="searchValue"
              @keyup.enter="searchByValue(searchValue)"
              :placeholder="$t('message.bookmark.search')"
            />
          </b-input-group>
        </div>
      </div>

      <transition name="fade">
        <div class="loading" v-show="loadingScroll">
          <b-spinner class="align-middle"></b-spinner>
          <strong> {{ $t("message.load") }}...</strong>
        </div>
      </transition>

      <div v-if="loading === true">
        <div class="text-center my-17">
          <b-spinner class="align-middle"></b-spinner>
          <strong> {{ $t("message.load") }}...</strong>
        </div>
      </div>

      <!-- Todo List -->
      <vue-perfect-scrollbar
        v-else
        :settings="perfectScrollbarSettings"
        ref="scroll"
        @scroll="onScroll"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <draggable
          v-model="todoListShow"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list"
        >
          <li
            v-for="task in todoListShow"
            :key="task.id"
            class="todo-item"
            :class="{ completed: task.status }"
            @click="handleTaskClick(getDataById(task.id))"
          >
            <!--<feather-icon
              icon="ArrowRightIcon"
              class="draggable-task-handle d-inline"
            />-->
            <div class="todo-title-wrapper">
              <div class="todo-title-area">
                <div class="title-wrapper">
                  <b-form-checkbox
                    :checked="task.status"
                    @click.native.stop
                    @change="changeStatus(task)"
                  />
                  <span class="todo-title" v-if="task.title.length > 100">
                    {{ task.title.substring(0, 100) + "..." }}</span
                  >
                  <span class="todo-title" v-else> {{ task.title }}</span>
                </div>
              </div>
              <div class="todo-item-action">
                <div class="badge-wrapper mr-1">
                  <b-badge
                    pill
                    class="text-capitalize"
                    variant="light-secondary"
                  >
                    <feather-icon
                      v-if="task.type == 'Publico'"
                      icon="GlobeIcon"
                      v-b-tooltip.hover.bottom="getTaskType(task.type)"
                    />
                    <feather-icon
                      v-else
                      icon="UserIcon"
                      v-b-tooltip.hover.bottom="getTaskType(task.type)"
                    />
                  </b-badge>
                  <b-badge
                    pill
                    :variant="getPillVariant(task.priority)"
                    class="text-capitalize"
                  >
                    {{ getTaskPriority(task.priority) }}
                  </b-badge>
                </div>
                <small class="text-nowrap text-muted mr-1">{{
                  localeDate(task.endDate)
                }}</small>
                <b-avatar
                  v-for="avatar in avatarFullName(task.userEmail)"
                  :key="avatar.avatar"
                  size="32"
                  v-b-tooltip.hover.bottom="avatar.fullName"
                  :text="avatar.avatar"
                />
              </div>
            </div>
          </li>
        </draggable>
        <div class="no-results" :class="{ show: !todoListShow.length }">
          <h5>{{ $t("message.registerNotFound") }}</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Todo AddNew -->
    <todo-add-new
      v-model="isTaskHandlerSidebarActive"
      :todo-type-options="todoTypeOptions"
      :todo-priority-options="todoPriorityOptions"
    />

    <!-- Todo Edit -->
    <todo-edit
      v-model="isTaskHandlerSidebarActiveEdit"
      :todo-type-options="todoTypeOptions"
      :todo-priority-options="todoPriorityOptions"
      :todo-data-edit="todoData"
    />

    <portal to="content-renderer-sidebar-left">
      <div class="sidebar-left">
        <div class="sidebar">
          <div class="sidebar-content todo-sidebar">
            <div class="todo-app-menu">
              <div class="add-task">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  @click="isTaskHandlerSidebarActive = true"
                >
                  {{ $t("message.todo.newTodo") }}
                </b-button>
              </div>
              <vue-perfect-scrollbar
                :settings="perfectScrollbarSettings"
                class="sidebar-menu-list scroll-area"
                v-if="changeLeftMenu"
              >
                <!-- Filters -->
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    @click="filterBy('all')"
                    style="cursor: pointer"
                    :active="filter === 'all'"
                  >
                    <feather-icon icon="ListIcon" size="18" class="mr-75" />
                    <span class="align-text-bottom line-height-1"
                      >{{ $t("message.todo.all") }} ({{ totalAll }})</span
                    >
                  </b-list-group-item>

                  <b-list-group-item
                    @click="filterBy('open')"
                    style="cursor: pointer"
                    :active="filter === 'open'"
                  >
                    <feather-icon icon="StarIcon" size="18" class="mr-75" />
                    <span class="align-text-bottom line-height-1"
                      >{{ $t("message.todo.open") }} ({{ totalOpen }})</span
                    >
                  </b-list-group-item>

                  <b-list-group-item
                    @click="filterBy('closed')"
                    style="cursor: pointer"
                    :active="filter === 'closed'"
                  >
                    <feather-icon icon="CheckIcon" size="18" class="mr-75" />
                    <span class="align-text-bottom line-height-1"
                      >{{ $t("message.todo.done") }} ({{ totalClosed }})</span
                    >
                  </b-list-group-item>
                </b-list-group>

                <!-- Tags -->
                <div class="mt-3 px-2 d-flex justify-content-between">
                  <h6 class="section-label mb-1">Tags</h6>
                </div>

                <b-list-group class="list-group-labels">
                  <b-list-group-item
                    @click="filterBy('urgent')"
                    style="cursor: pointer"
                    :active="filter === 'urgent'"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-danger`"
                    />
                    <span
                      >{{ $t("message.todo.urgent") }} ({{ totalUrgent }})</span
                    >
                  </b-list-group-item>
                </b-list-group>

                <b-list-group class="list-group-labels">
                  <b-list-group-item
                    @click="filterBy('high')"
                    style="cursor: pointer"
                    :active="filter === 'high'"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-warning`"
                    />
                    <span>{{ $t("message.todo.high") }} ({{ totalHigh }})</span>
                  </b-list-group-item>
                </b-list-group>

                <b-list-group class="list-group-labels">
                  <b-list-group-item
                    @click="filterBy('normal')"
                    style="cursor: pointer"
                    :active="filter === 'normal'"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-primary`"
                    />
                    <span
                      >{{ $t("message.todo.normal") }} ({{ totalNormal }})</span
                    >
                  </b-list-group-item>
                </b-list-group>

                <!-- Filter -->
                <div class="mt-3 px-2 d-flex justify-content-between">
                  <h6 class="section-label mb-1">
                    {{ $t("message.todo.teams") }}
                  </h6>
                </div>

                <b-list-group class="list-group-labels">
                  <b-list-group-item>
                    <v-select
                      v-model="teamSelected"
                      :options="teamOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="task-status"
                      @input="filterByTeam()"
                    />
                  </b-list-group-item>
                </b-list-group>
              </vue-perfect-scrollbar>

              <vue-perfect-scrollbar
                :settings="perfectScrollbarSettings"
                class="sidebar-menu-list scroll-area"
                v-else
              >
                <!-- Filters -->
                <b-list-group class="list-group-filters">
                  <b-list-group-item active>
                    <feather-icon icon="SearchIcon" size="18" class="mr-75" />
                    <span class="align-text-bottom line-height-1"
                      >{{ $t("message.chart.result") }} ({{ totalAll }})</span
                    >
                  </b-list-group-item>

                  <b-list-group-item style="color: lightgray">
                    <feather-icon icon="StarIcon" size="18" class="mr-75" />
                    <span class="align-text-bottom line-height-1"
                      >{{ $t("message.todo.open") }} ({{ totalOpen }})</span
                    >
                  </b-list-group-item>

                  <b-list-group-item style="color: lightgray">
                    <feather-icon icon="CheckIcon" size="18" class="mr-75" />
                    <span class="align-text-bottom line-height-1"
                      >{{ $t("message.todo.done") }} ({{ totalClosed }})</span
                    >
                  </b-list-group-item>
                </b-list-group>

                <!-- Tags -->
                <div class="mt-3 px-2 d-flex justify-content-between">
                  <h6 class="section-label mb-1">Tags</h6>
                </div>

                <b-list-group class="list-group-labels">
                  <b-list-group-item style="color: lightgray">
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-secondary`"
                    />
                    <span
                      >{{ $t("message.todo.urgent") }} ({{ totalUrgent }})</span
                    >
                  </b-list-group-item>
                </b-list-group>

                <b-list-group class="list-group-labels">
                  <b-list-group-item style="color: lightgray">
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-secondary`"
                    />
                    <span>{{ $t("message.todo.high") }} ({{ totalHigh }})</span>
                  </b-list-group-item>
                </b-list-group>

                <b-list-group class="list-group-labels">
                  <b-list-group-item style="color: lightgray">
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-secondary`"
                    />
                    <span
                      >{{ $t("message.todo.normal") }} ({{ totalNormal }})</span
                    >
                  </b-list-group-item>
                </b-list-group>

                <!-- Filter -->
                <div class="mt-3 px-2 d-flex justify-content-between">
                  <h6 class="section-label mb-1">
                    {{ $t("message.todo.teams") }}
                  </h6>
                </div>

                <b-list-group class="list-group-labels">
                  <b-list-group-item>
                    <v-select
                      v-model="teamSelected"
                      :options="teamOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="task-status"
                      @input="filterByTeam()"
                    />
                  </b-list-group-item>
                </b-list-group>
              </vue-perfect-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import {
  BFormInput,
  VBTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BBadge,
  BAvatar,
  BCard,
  BButton,
  BListGroup,
  BListGroupItem,
  BTooltip,
  BSpinner,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import { formatDate, avatarText } from "@core/utils/filter";
import { useRouter } from "@core/utils/utils";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import todoStoreModule from "./todoStoreModule";
import TodoAddNew from "./TodoAddNew.vue";
import TodoEdit from "./TodoEdit.vue";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    VuePerfectScrollbar,
    BCard,
    BButton,
    BListGroup,
    BListGroupItem,
    vSelect,
    BTooltip,
    BSpinner,

    // App SFC
    TodoAddNew,
    TodoEdit,
  },

  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },

  data() {
    return {
      todoList: [],
      todoListShow: [],
      todoData: {},
      todoTypeOptions: [],
      teamOptions: [],
      todoPriorityOptions: [],
      userData: store.state.user.userData,

      teamSelected: "none",
      searchValue: "",

      loading: true,
      filterSelect: "",

      currentPage: 0,
      loadingScroll: false,

      totalNumberList: 0,
      totalLenghtLoaded: 0,

      //Param
      paramData: store.state.user.paramData,

      //Total Values
      totalUrgent: 0,
      totalHigh: 0,
      totalNormal: 0,
      totalOpen: 0,
      totalClosed: 0,
      totalAll: 0,

      filter: "all",

      changeLeftMenu: true,
    };
  },

  mounted() {
    this.$root.$on("newListTodo", (newListTodo) => {
      if (this.searchValue === "") {
        this.getAllData("update");
      } else {
        this.getAllDataByWord(this.searchValue);
      }
    });
  },

  async created() {
    await this.getAllData("load");

    var typeList = [];
    await axios
      .get(`${URL_API}todo_type`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].type;
          typeList.push(element);
          this.todoTypeOptions.push({
            label: this.getColLabel(typeList[i]),
            value: typeList[i],
          });
        }
      });

    var priorityList = [];
    await axios
      .get(`${URL_API}todo_priority`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].priority;
          priorityList.push(element);
          this.todoPriorityOptions.push({
            label: this.getColLabel(priorityList[i]),
            value: priorityList[i],
          });
        }
      });

    var teamList = [];
    await axios
      .get(`${URL_API}team/byUser/${this.userData.id}`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i];
          teamList.push(element);
          this.teamOptions.push({
            label: teamList[i].name,
            value: teamList[i].id,
          });
        }
        this.teamOptions.push({
          label: this.getColLabel("none"),
          value: "none",
        });
      });
  },

  methods: {
    getColLabel(colName) {
      return this.$i18n.t("message.todo." + colName);
    },

    getPillVariant(priority) {
      if (priority === "Urgente") {
        return "light-danger";
      } else if (priority === "Alta") {
        return "light-warning";
      } else {
        return "light-primary";
      }
    },

    async onScroll(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target;
      if (scrollTop + offsetHeight >= scrollHeight) {
        if (this.todoListShow.length != this.totalNumberList) {
          // Só mostrar loading se elementos n for igual ao tamanho total
          this.loadingScroll = true;
        }

        this.getAllData("load");
      }
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    getTaskPriority(priority) {
      if (priority === "Urgente") {
        return this.getColLabel("urgent");
      } else if (priority === "Alta") {
        return this.getColLabel("high");
      } else {
        return this.getColLabel("normal");
      }
    },

    getTaskType(type) {
      if (type === "Publico") {
        return this.getColLabel("public");
      } else {
        return this.getColLabel("private");
      }
    },

    avatarFullName(email) {
      var listReturn = [];

      if (email != "") {
        listReturn.push({
          fullName: email.split(" ").slice(0, 2).join(" "),
          avatar: avatarText(email.split(" ").slice(0, 2).join(" ")),
        });

        return listReturn;
      }

      return listReturn;
    },

    async getAllData(operation) {
      this.searchValue = ""; //Sempre zerar o valor da pesquisa em qlqr intereção com filtros
      this.changeLeftMenu = true;
      await axios
        .get(`${URL_API}todo/count/${this.userData.id}/${this.teamSelected}`, {
          //Valores para totais em legenda
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.totalUrgent = response.data[0];
          this.totalHigh = response.data[1];
          this.totalNormal = response.data[2];
          this.totalOpen = response.data[3];
          this.totalClosed = response.data[4];
          this.totalAll = this.totalOpen + this.totalClosed;
        });

      if (this.totalNumberList === 0) {
        this.loading = true;
      }

      if (operation === "load") {
        await axios
          .get(
            `${URL_API}todo/list/${this.userData.id}/${this.filter}/${this.teamSelected}?page=${this.currentPage}&sort=id,${this.paramData.listSort}&size=20`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            Array.prototype.push.apply(this.todoList, response.data.content); //this.todoList soma com lista retorno
            this.todoListShow = this.todoList;
            this.totalLenghtLoaded = this.todoListShow.length;
            this.loading = false;
            this.loadingScroll = false;
            this.currentPage = this.currentPage + 1;
            this.totalNumberList = response.data.totalElements;
          });
      } else if (operation === "update") {
        this.loading = true;
        this.todoList = [];
        this.todoListShow = [];
        await axios
          .get(
            `${URL_API}todo/list/${this.userData.id}/${this.filter}/${this.teamSelected}?page=0&sort=id,${this.paramData.listSort}&size=${this.totalLenghtLoaded}`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            this.todoList = response.data.content;
            this.todoListShow = response.data.content;
            this.loading = false;
            this.loadingScroll = false;

            /*if (this.totalNumberList < response.data.totalElements) {
              //Checar se é uma nova entrada e voltar ao topo
              this.$refs.scroll.$el.scrollTop = 0;
            }*/

            this.totalNumberList = response.data.totalElements;
          });
      } else if (operation === "update-status") {
        await axios
          .get(
            `${URL_API}todo/list/${this.userData.id}/${this.filter}/${this.teamSelected}?page=0&sort=id,${this.paramData.listSort}&size=${this.totalLenghtLoaded}`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            this.todoList = response.data.content;
            this.todoListShow = response.data.content;
            this.loading = false;
            this.loadingScroll = false;

            this.totalNumberList = response.data.totalElements;
            this.$root.$emit("newStatusTodo", null);
          });
      }
    },

    async getAllDataByWord(word) {
      this.totalUrgent = 0;
      this.totalHigh = 0;
      this.totalNormal = 0;
      this.totalOpen = 0;
      this.totalClosed = 0;
      this.totalAll = 0;

      await axios
        .get(`${URL_API}todo/${this.teamSelected}/search?searchTerm=${word}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.todoListShow = response.data;
          this.todoList = response.data;
          this.loading = false;
          this.loadingScroll = false;
          this.totalNumberList = 0;
          this.totalLenghtLoaded = 0;

          this.totalAll = this.todoListShow.length;
        });
    },

    async getDataById(id) {
      await axios
        .get(`${URL_API}todo/${id}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.todoData = response.data;
        });
    },

    changeStatus(todo) {
      axios({
        method: "put",
        url: `${URL_API}todo/status/${todo.id}`,
        headers: getHeader(this.userData),
        data: todo,
      })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.updated"),
              icon: "CheckIcon",
              variant: "success",
              text: this.$i18n.t("message.todo.updated"),
            },
          });
        })
        .then(() => {
          if (this.searchValue === "") {
            this.getAllData("update-status");
          } else {
            this.getAllDataByWord(this.searchValue);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    searchByValue(value) {
      if (value != "") {
        this.todoList = [];
        this.todoListShow = [];
        this.currentPage = 0;
        this.loading = true;
        this.filter = "all";
        this.changeLeftMenu = false;
        this.getAllDataByWord(value);
      } else {
        this.todoList = [];
        this.todoListShow = [];
        this.changeLeftMenu = true;
        this.getAllData("load");
      }
    },

    filterBy(value) {
      this.filter = value;
      this.todoList = [];
      this.todoListShow = [];
      this.currentPage = 0;
      this.loading = true;

      this.getAllData("load");
    },

    filterByTeam() {
      this.todoList = [];
      this.todoListShow = [];
      this.currentPage = 0;
      this.loading = true;

      this.getAllData("load");
    },
  },

  setup() {
    const TODO_APP_STORE_MODULE_NAME = "app-todo";

    // Register module
    if (!store.hasModule(TODO_APP_STORE_MODULE_NAME))
      store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TODO_APP_STORE_MODULE_NAME))
        store.unregisterModule(TODO_APP_STORE_MODULE_NAME);
    });

    const { route, router } = useRouter();
    const routeSortBy = computed(() => route.value.query.sort);
    const routeQuery = computed(() => route.value.query.q);
    const routeParams = computed(() => route.value.params);
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchTasks();
    });

    const tasks = ref([]);

    const sortOptions = [
      "latest",
      "title-asc",
      "title-desc",
      "assignee",
      "due-date",
    ];
    const sortBy = ref(routeSortBy.value);
    watch(routeSortBy, (val) => {
      if (sortOptions.includes(val)) sortBy.value = val;
      else sortBy.value = val;
    });
    const resetSortAndNavigate = () => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query));

      delete currentRouteQuery.sort;

      router
        .replace({ name: route.name, query: currentRouteQuery })
        .catch(() => {});
    };

    const blankTask = {
      id: null,
      title: "",
      dueDate: new Date(),
      description: "",
      assignee: null,
      tags: [],
      isCompleted: false,
      isDeleted: false,
      isImportant: false,
    };
    const task = ref(JSON.parse(JSON.stringify(blankTask)));
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask));
    };
    const removeTask = () => {
      store.dispatch("app-todo/removeTask", { id: task.value.id }).then(() => {
        // eslint-disable-next-line no-use-before-define
        fetchTasks();
      });
    };
    const updateTask = (taskData) => {
      store.dispatch("app-todo/updateTask", { task: taskData }).then(() => {
        // eslint-disable-next-line no-use-before-define
        fetchTasks();
      });
    };

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    const isTaskHandlerSidebarActive = ref(false);
    const isTaskHandlerSidebarActiveEdit = ref(false);

    const taskTags = [
      {
        title: "Team",
        color: "primary",
        route: { name: "apps-todo-tag", params: { tag: "team" } },
      },
    ];

    const resolveTagVariant = (tag) => {
      if (tag === "team") return "primary";
      if (tag === "low") return "success";
      if (tag === "medium") return "warning";
      if (tag === "high") return "danger";
      if (tag === "update") return "info";
      return "primary";
    };

    const resolveAvatarVariant = (tags) => {
      if (tags.includes("high")) return "primary";
      // if (tags.includes("medium")) return "warning";
      // if (tags.includes("low")) return "success";
      // if (tags.includes("update")) return "danger";
      // if (tags.includes("team")) return "info";
      return "primary";
    };

    // Search Query
    const searchQuery = ref(routeQuery.value);
    watch(routeQuery, (val) => {
      searchQuery.value = val;
    });
    // eslint-disable-next-line no-use-before-define
    watch([searchQuery, sortBy], () => fetchTasks());
    const updateRouteQuery = (val) => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query));

      if (val) currentRouteQuery.q = val;
      else delete currentRouteQuery.q;

      router.replace({ name: route.name, query: currentRouteQuery });
    };

    const fetchTasks = () => {
      store
        .dispatch("app-todo/fetchTasks", {
          q: searchQuery.value,
          filter: router.currentRoute.params.filter,
          tag: router.currentRoute.params.tag,
          sortBy: sortBy.value,
        })
        .then((response) => {
          tasks.value = response.data;
        });
    };

    fetchTasks();

    const handleTaskClick = (taskData) => {
      task.value = taskData;
      isTaskHandlerSidebarActiveEdit.value = true;
    };

    // Single Task isCompleted update
    const updateTaskIsCompleted = (taskData) => {
      // eslint-disable-next-line no-param-reassign
      taskData.isCompleted = !taskData.isCompleted;
      updateTask(taskData);
    };

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    return {
      task,
      tasks,
      removeTask,
      updateTask,
      clearTaskData,
      taskTags,
      searchQuery,
      fetchTasks,
      perfectScrollbarSettings,
      updateRouteQuery,
      resetSortAndNavigate,

      // UI
      resolveTagVariant,
      resolveAvatarVariant,
      isTaskHandlerSidebarActive,
      isTaskHandlerSidebarActiveEdit,

      // Click Handler
      handleTaskClick,

      // Filters
      formatDate,
      avatarText,

      // Single Task isCompleted update
      updateTaskIsCompleted,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}

.my-17 {
  margin-top: 21.2rem;
  margin-bottom: 17.2rem;
}

.loading {
  text-align: center;
  position: absolute;
  color: #fff;
  z-index: 9;
  background: grey;
  padding: 8px 18px;
  border-radius: 5px;
  left: calc(55% - 45px);
  top: calc(90% - 18px);
  height: 50px;
  width: 200px;
  opacity: 50%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
